import { css } from 'astroturf';
import React, { useState } from 'react';

import ListItem from './ListItem';

const styles = css`
  .outer {
    background: linear-gradient(145deg, #012b, #0126, #012b);
    max-width: min(920px, 100vw - 2rem);
    margin: 0rem auto 0;
    padding: 0.75rem;
    border-radius: 1rem;
    box-shadow:
      rgba(255, 255, 255, 0.25) 0px 1px 1px 0px inset,
      rgba(50, 50, 93, 0.6) 0px 50px 100px -20px,
      rgba(0, 0, 0, 0.7) 0px 30px 60px -30px,
      rgba(103, 178, 111, 0.6) -10px 10px 60px -10px;

    &:nth-child(2) {
      & h3 {
        color: #4da758;
      }

      & .intro::before {
        background: #67b26f66;
        background: oklab(0.66 -0.24 0.16 / 0.5);
      }
    }

    &:nth-child(1) {
      & .button {
        background: linear-gradient(135deg, #107db5, #107db5);
      }
    }
  }

  .price {
    overflow: hidden;
    position: relative;
    text-align: left;
    display: flex;
    height: 100%;
    flex-direction: column;
    align-items: stretch;
    justify-content: stretch;
    flex-wrap: wrap;
    gap: 1rem;
    position: relative;
    padding: 1.5rem 2rem;
    border-radius: 0.6rem;
    background: #0128;
    box-shadow:
      rgba(255, 255, 255, 0.6) 0px 1px 1px 0px inset,
      rgba(50, 50, 93, 0.7) 0px 5px 10px;
    color: #fff;
    text-align: left;
    display: flex;
    flex-direction: column;

    & > svg {
      align-self: flex-end;
    }

    & .intro {
      position: relative;
      text-align: left;
      margin-bottom: 3rem;

      & h3 {
        position: relative;
        z-index: 1;
        font-weight: 800;
        font-size: 1.5rem;
        color: #fff;
      }

      &::before {
        z-index: 0;
        content: ' ';
        position: absolute;
        left: 50%;
        top: 50%;
        width: 50rem;
        height: 30rem;
        margin-left: -25rem;
        margin-top: -25rem;
        border-radius: 100%;
        background: #4ca2cd66;
        background: oklab(0.61 -0.08 -0.21 / 0.66);
        opacity: 1;
        pointer-events: none;
      }

      & p {
        position: relative;
        z-index: 1;
        max-width: 13rem;
        & strong {
          color: #fff;
        }
      }
    }
    & .row {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      flex-direction: column;
      margin-bottom: 1rem;
      text-align: center;
    }

    & .bottomSmall {
      opacity: 0.6;
      display: block;
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0rem;
      text-align: center;
      padding: 0 2rem;
      height: 2rem;
    }

    & .inbetween {
      margin: 0;
      text-align: center;
      color: #757589;

      & span {
        font-variant: small-caps;
      }
    }

    & h3 {
      color: #107db5;
      font-size: 2rem;
    }

    & h4 {
      text-align: left;
      margin: 0 0 0.25rem;
      line-height: 1;
      font-size: 2rem;
      text-align: left;
      font-weight: 700;

      & > span {
        position: relative;
        display: inline-block;
        text-align: left;
        height: 32px;
        vertical-align: bottom;
        overflow: hidden;
        white-space: nowrap;

        &.showYearly {
          & > span {
            transform: translateY(0px);
          }
        }
        & > span {
          display: block;
          transition: 0.3s ease-out transform;
          transform: translateY(-32px);

          & span {
          }
        }
      }

      & small {
        color: #9595a9;
        font-size: 0.75rem;
        font-weight: 400;
        display: inline-block;
        margin-left: 0.25rem;
        margin-right: 0.5rem;
        & span {
          transition: 0.25s ease-in-out opacity;
          &.hidden {
            opacity: 0;
            pointer-events: none;
          }
        }
      }
    }

    & del {
      font-weight: 500;
      font-size: 1.2rem;
      color: #9595a9;
      text-decoration-color: #f00d;
      text-decoration-style: wavy;
      text-decoration-thickness: 3px;
      text-decoration-line: line-through;
    }

    & .percentage {
      vertical-align: middle;
      display: inline-block;
      font-style: normal;
      font-weight: bold;
      font-size: 12px;
      text-align: center;
      color: #fff;
      background: #cf2c43;
      border-radius: 0 4px 4px 0;
      padding: 0 5px 0 0;
      line-height: 16px;
      height: 16px;
      margin: -5px 0 0 16px;
      position: relative;

      &::before {
        position: absolute;
        top: 0;
        left: -8px;
        content: ' ';
        display: inline-block;
        border-top: 8px solid transparent;
        border-bottom: 8px solid transparent;
        border-right: 8px solid #cf2c43;
        height: 14px;
        padding: 0;
        margin: 0;
        border-radius: 0;
      }
    }

    & p {
      color: rgb(185, 185, 185);
      line-height: 1.25;
      margin-bottom: 0;
      transition: 0.25s ease-in-out opacity;
      opacity: 1;
      text-align: left;

      &.hidden {
        opacity: 0;
        pointer-events: none;
        transition: 0s ease-in-out opacity;
      }

      & strong {
        color: rgb(185, 185, 185);
        font-weight: 500;
      }

      & ins {
        text-decoration: none;
        font-size: 1.1em;
      }
      & del {
        font-size: 1em;
      }
    }

    & ul {
      max-width: 210px;
      list-style: none;
      margin: 0 1.5rem 2rem;
      & li {
        position: relative;
        font-size: 0.875rem;
        & + li {
          margin-top: 0.5rem;
        }
      }

      & > li > svg:first-child {
        margin-left: 0;
        position: absolute;
        left: -22px;
        top: 4px;
        background: #67b26f;
        stroke: #fff;
        border-radius: 100%;
        padding: 2px;
        vertical-align: text-bottom;
      }

      & > li > span svg {
        margin-left: 5px;
        vertical-align: text-bottom;
      }
    }

    & .buttonWrap {
      display: flex;
      flex-direction: column;
      border-radius: 2rem;
      margin: auto 0 1rem;

      & small {
        display: block;
        text-align: center;
        opacity: 1;
        transform: translateX(0);
        font-size: 0.875rem;
        margin: 1rem 0 0;
        line-height: 1rem;
      }
    }

    & .button {
      position: relative;
      background: #67b26f linear-gradient(135deg, #67b26f, #67b26f);
      text-align: center;
      font-weight: 600;
      font-size: 18px;
      color: #fff;
      border: 0;
      border-radius: 80px;
      width: 100%;
      height: 2.5rem;
      line-height: 2.5rem;
      transition: background 0.25s ease-in-out;
      overflow: hidden;
      cursor: pointer;
      margin-left: auto;
      margin-right: auto;

      &:hocus {
        &::before {
          opacity: 1;
        }
      }

      & span {
        position: relative;
        z-index: 1;
      }

      &::before {
        will-change: opacity;
        content: ' ';
        display: block;
        background: #4ca2cd linear-gradient(135deg, #4ca2cd, #67b26f);
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        z-index: 0;
        opacity: 0;
        transition: opacity 0.25s ease-in-out;
        border-radius: 7px;
      }
    }
  }
`;

const Column = (props) => {
  const [requestQuoteVisible, setRequestQuoteVisible] = useState(false);
  const { priceInfo, priceYearly, localPrice, setSignUpVisible, highlighted } = props;
  const { id, vat, hasVat } = priceInfo[priceYearly ? 0 : 1];

  const openCheckout = (e) => {
    e.preventDefault();
    if (props.name === 'Free trial') {
      location.href = 'https://dashboard.polypane.app/register/?ref=pricingtable';
    } else if (id) {
      setSignUpVisible({
        id,
        visible: true,
        name: props.name,
        priceYearly,
        total: localPrice,
        priceInfo: priceInfo[priceYearly ? 0 : 1],
      });

      if (typeof window !== 'undefined' && window.umami) {
        window.umami.track((data) => ({ ...data, url: '/pricing/checkout/', title: 'Checkout' }));
      }

      if (typeof window !== 'undefined' && window.ga) {
        window.ga('send', {
          hitType: 'pageview',
          title: 'Signup form',
          location: `${window.location.href}${window.location.href.endsWith('/') ? '' : '/'}signup-form`,
          page: `${window.location.pathname}${window.location.href.endsWith('/') ? '' : '/'}signup-form`,
        });
      }
    } else {
      setRequestQuoteVisible(true);
      if (typeof window !== 'undefined' && window.ga) {
        window.ga('send', {
          hitType: 'pageview',
          title: 'Request quote form',
          location: `${window.location.href}${window.location.href.endsWith('/') ? '' : '/'}request-quote-form`,
          page: `${window.location.pathname}${window.location.href.endsWith('/') ? '' : '/'}request-quote-form`,
        });
      }
    }
  };

  return (
    <div className={styles.outer}>
      <div className={styles.price}>
        <div className={styles.intro}>
          <h3>{props.name}</h3>
          <p>
            <strong>{props.desc}</strong>
          </p>
        </div>

        <div className={styles.row}>
          <div>
            <del>{props.oldMonthlyLocalPrice}</del> <span className={styles.percentage}>-25%</span>
          </div>
          <h4>
            {props.name === 'Enterprise' ? (
              <>Custom</>
            ) : props.name === 'Free trial' ? (
              <>0</>
            ) : (
              <span className={priceYearly ? styles.showYearly : ''}>
                <span>
                  {priceInfo
                    .filter((x) => x.price.trim())
                    .map((item, index) => (
                      <React.Fragment key={item.price}>
                        <span aria-hidden={(priceYearly && index === 0) || (!priceYearly && index === 1)}>
                          {item.price}
                        </span>
                        <br />
                      </React.Fragment>
                    ))}
                </span>
              </span>
            )}
            {props.name === 'Enterprise' || props.name === 'Free trial' ? (
              <></>
            ) : (
              <small>
                / month
                <span className={priceYearly ? styles.hidden : ''}>
                  {priceInfo[priceYearly ? 0 : 1].hasVat ? ' including tax' : ''}
                </span>
              </small>
            )}
          </h4>
          <p className={!priceYearly ? styles.hidden : ''}>
            <small>
              {props.name !== 'Enterprise' && props.name !== 'Free trial' ? (
                <>
                  <del>{props.oldLocalPrice}</del> {props.localPrice} billed yearly
                  {priceInfo[priceYearly ? 0 : 1].hasVat ? ' including tax' : ''}
                </>
              ) : (
                <>&nbsp;</>
              )}
            </small>
          </p>
        </div>

        <ul>
          {props.benefits.map((benefit, index) => (
            <ListItem key={benefit} tip={(props.tooltips && props.tooltips[index]) || false}>
              {benefit}
            </ListItem>
          ))}
        </ul>

        <div className={styles.buttonWrap}>
          <button type="button" className={styles.button} onClick={openCheckout} id={props.buttonID}>
            <span>{props.buttonText}</span>
          </button>
        </div>

        {props.name === 'Free trial' ? <small className={styles.bottomSmall}>No credit card required.</small> : ''}

        {props.name !== 'Enterprise' && props.name !== 'Free trial' ? (
          <small className={styles.bottomSmall}>Secure payment. Cancel anytime.</small>
        ) : (
          ''
        )}
        {!id && <RequestQuote visible={requestQuoteVisible} setVisible={setRequestQuoteVisible} />}
      </div>
    </div>
  );
};

export default Column;
