import React, { Component } from 'react';
import Loadable from '@loadable/component';
import { css } from 'astroturf';
import { Lock } from 'react-bytesize-icons';

import SEO from '../../components/SEO';
import Link from '../../components/link';
import Layout from '../../layout/index';
import PageHeader from '../../components/PageHeader';
import Testimonials from '../../components/black-friday/Testimonials';
import ExpenseInfoBox from '../../components/black-friday/ExpenseInfoBox';

import BlackFridayHeader from '../../components/black-friday/header';

import Table from '../../components/black-friday/Table';

import CTA from '../../components/CTA';

const LoadableCountdown = Loadable(() => import('../../components/black-friday/Countdown'), {
  fallback: (
    <div className="Countdown-styles-module--countdown--1DD26">
      <span className="Countdown-styles-module--col--3Mp9A">
        <strong>
          <div className="odometer odometer-theme-dark">
            <div className="odometer-inside">
              <span className="odometer-digit">
                <span className="odometer-digit-spacer">8</span>
                <span className="odometer-digit-inner">
                  <span className="odometer-ribbon">
                    <span className="odometer-ribbon-inner">
                      <span className="odometer-value">0</span>
                    </span>
                  </span>
                </span>
              </span>
            </div>
          </div>
        </strong>
        <span>Days</span>
      </span>
      <span className="Countdown-styles-module--col--3Mp9A">
        <strong>
          <div className="odometer odometer-theme-dark">
            <div className="odometer-inside">
              <span className="odometer-digit">
                <span className="odometer-digit-spacer">8</span>
                <span className="odometer-digit-inner">
                  <span className="odometer-ribbon">
                    <span className="odometer-ribbon-inner">
                      <span className="odometer-value">0</span>
                    </span>
                  </span>
                </span>
              </span>
            </div>
          </div>
        </strong>
        <span>Hours</span>
      </span>
      <span className="Countdown-styles-module--col--3Mp9A">
        <strong>
          <div className="odometer odometer-theme-dark">
            <div className="odometer-inside">
              <span className="odometer-digit">
                <span className="odometer-digit-spacer">8</span>
                <span className="odometer-digit-inner">
                  <span className="odometer-ribbon">
                    <span className="odometer-ribbon-inner">
                      <span className="odometer-value">0</span>
                    </span>
                  </span>
                </span>
              </span>
            </div>
          </div>
        </strong>
        <span>Minutes</span>
      </span>
      <span className="Countdown-styles-module--col--3Mp9A">
        <strong>
          <div className="odometer odometer-theme-dark">
            <div className="odometer-inside">
              <span className="odometer-digit">
                <span className="odometer-digit-spacer">8</span>
                <span className="odometer-digit-inner">
                  <span className="odometer-ribbon">
                    <span className="odometer-ribbon-inner">
                      <span className="odometer-value">0</span>
                    </span>
                  </span>
                </span>
              </span>
            </div>
          </div>
        </strong>
        <span>Seconds</span>
      </span>
    </div>
  ),
});

const styles = css`
  .pricingtable {
    color: #f8f8fb;
    text-align: center;

    & > h2 {
      margin: 4rem auto 1rem;
      font-weight: 900;
      font-size: clamp(18px, 16px + 2vw, 48px);
      line-height: 1;
      max-width: 30ch;
      color: #fff;
      filter: drop-shadow(0px 3px 0px #000);
    }

    & > h3 {
      margin: 0rem auto 1rem;
      font-weight: 900;
      font-size: clamp(16px, 14px + 2vw, 26px);
      line-height: 1;
      max-width: 30ch;
      color: #fff;
      filter: drop-shadow(0px 3px 0px #000);
    }

    & > small {
      max-width: 45rem;
      margin: 1rem auto 0;
      display: inline-block;
      padding-left: 2rem;
      padding-right: 2rem;
      font-size: 0.9rem;
      line-height: 1.5rem;
      font-weight: 400;
      color: #67b26f;
      text-wrap: balance;

      & svg {
        vertical-align: text-top;
        margin-right: 0.25rem;
      }
    }
  }
  .punchout {
    background: linear-gradient(135deg, #2c82ad, ease-in-out, #67b26f);
    background-image: linear-gradient(135deg, oklab(0.61 -0.08 -0.21), oklab(0.66 -0.24 0.16));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    box-decoration-break: clone;
    -webkit-box-decoration-break: clone;
    box-decoration-break: clone;
  }

  .faq {
    max-width: 60rem;
    text-align: left;
    padding: 0 2rem;
    margin: 4rem auto 0;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    gap: 3rem;

    & div {
      flex: 1 1 320px;
    }

    & h2 {
      flex: 1 0 100%;
      border-bottom: 3px solid;
      color: #4ca2cd;
    }

    & h3 {
      color: #58b993;
      font-size: 1rem;
      margin-bottom: 0.5rem;
    }

    & p {
    }
  }
  .primaryButton {
    position: relative;
    pointer-events: all;
    margin: 8px 8px 4rem;
    font-size: calc(14px + 0.5vw);
    font-weight: 700;
    color: rgba(255, 255, 255, 1);
    background: linear-gradient(135deg, #2c82ad, #67b26f);
    border-radius: 40px;
    text-align: center;
    display: inline-block;
    text-decoration: none;
    line-height: 26px;
    transition: all 0.25s ease-in-out;
    padding: 0;

    & i {
      position: absolute;
      top: calc(100% + 10px);
      line-height: 1;
      left: 0;
      right: 0;
      text-align: center;
      font-size: 12px;
      font-weight: normal;
      color: #fff;
      display: block;
      font-style: normal;
      opacity: 0.75;
    }

    & span {
      position: relative;
      z-index: 1;
      padding: 0.33em 1rem;
      content: ' ';
      display: block;
      background: transparent;
      width: calc(100%);
      height: calc(100%);
      z-index: 0;
      opacity: 1;
      transition: background 0.25s ease-in-out;
      border-radius: 40px;
    }

    & svg {
      vertical-align: middle;
      margin-top: -3px;
      margin-left: 10px;
    }

    @media (min-width: 600px) {
      & span {
        font-size: 1.125rem;
        padding: 0.5em 1rem;
      }
    }

    &:hocus {
      text-decoration: none;
      & span {
        background: #0a1530;
      }
    }
  }
  .testimonials {
    max-width: 60rem;
    margin: 2rem auto 0;
    text-align: center;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
    flex-wrap: wrap;
    gap: 2rem;
    position: relative;
    padding: 1.5rem;
    border-radius: 0.5rem;
  }
  .smalltestimonial {
    max-width: 17rem;
    flex: 1 1 20rem;
    display: flex;
    gap: 1rem;
    align-content: center;
    flex-direction: column;

    & div.image {
      flex: 0 0 60px;
      text-align: center;
      & img {
        border-radius: 100%;
        border: 3px solid rgb(88, 185, 147);
      }
    }

    & div.quote {
      font-size: 0.875rem;
      text-align: center;

      & q,
      & span {
        display: block;
      }

      & span {
        color: rgb(88, 185, 147);
        margin-top: 1rem;
        line-height: 1;
      }
    }
  }

  .endDate {
    color: rgb(88, 185, 147);
    font-weight: bold;
    margin-bottom: 0;
  }

  .graphicwrap {
    width: 120%;
    left: -10%;
    top: 10%;
    height: 150%;
    position: absolute;
    filter: blur(75px);
    opacity: 0.5;
    overflow: hidden;
    max-width: 100vw;
    pointer-events: none;
  }
  .graphic {
    inset: 0;
    height: 700px;
    background: linear-gradient(90deg, #0a6a9e 0, rgba(103, 178, 111, 0.6) 100%);
    clip-path: polygon(
      100% 61.6%,
      97.5% 26.9%,
      85.5% 0.1%,
      74.1% 44.1%,
      80.7% 2%,
      72.5% 32.5%,
      52.4% 68.1%,
      60.2% 62.4%,
      47.5% 58.3%,
      27.5% 76.7%,
      0.1% 64.9%,
      45.2% 34.5%,
      17.9% 100%,
      27.6% 76.8%,
      76.1% 97.7%,
      74.1% 44.1%
    );
  }
`;

const enddate = '2024-12-02T23:59:59';
const readableEndDate = 'Monday, the 2nd';

class Page extends Component {
  constructor() {
    super();

    this.state = {
      priceYearly: true,
    };
  }

  togglePricing = (value) => this.setState({ priceYearly: value });

  render() {
    return (
      <Layout background="rgb(53, 53, 70)" darkHeader noBar>
        <SEO
          title="Polypane Black Friday"
          description="Get the browser for ambitious web developers"
          keywords={['']}
          pathname={this.props.location.pathname}
          ogFileName="blackfriday"
          ogCustomPath="/og-custom/"
        />
        <PageHeader nobg overflow>
          <BlackFridayHeader />
          <h1 style={{ color: '#fff', filter: 'drop-shadow(0px 3px 0px #000)', textAlign: 'center' }}>
            ✨ Polypane <span className={styles.punchout}>Black Friday</span> Deal ✨
          </h1>
          <h2
            style={{
              color: '#fff',
              fontWeight: 800,
              filter: 'drop-shadow(0px 3px 0px #000)',
              fontSize: 'clamp(16px, 16px + 2vw, 36px)',
            }}
          >
            The Black Friday deal is no longer available.
          </h2>
          <p
            style={{
              color: '#fff',
              fontWeight: 800,
              filter: 'drop-shadow(0px 3px 0px #000)',
              textWrap: 'balance',
            }}
          >
            Check out our <Link to="/pricing/">regular pricing</Link> and our{' '}
            <Link to="/special-offers">promotions and discounts</Link> and get started!
          </p>
          <div className={styles.graphicwrap}>
            <div className={styles.graphic}></div>
          </div>
        </PageHeader>

        <div className={styles.pricingtable}>
          <Testimonials />
          <h3
            style={{
              marginTop: '5rem',
            }}
          >
            Start a trial below:
          </h3>
          <CTA darkbg />
        </div>
      </Layout>
    );
  }
}

export default Page;
