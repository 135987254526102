import { css } from 'astroturf';
import React, { Component } from 'react';
import Link from '../link';

const styles = css`
  .expense {
    position: relative;
    margin: 0 auto;
    text-align: left;
    max-width: 42rem;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    flex-direction: column;
    padding: 1rem;
    font-size: 1rem;

    @media (min-width: 400px) {
      padding: 1.5rem 0;
      flex-direction: row;
    }

    & svg {
      flex: 0 0 128px;
      stroke: #107db5;
      stroke: oklab(0.61 -0.08 -0.21);
      filter: drop-shadow(0px 3px 0px #000);
    }

    & h2 {
      font-size: 26px;
      line-height: 1.1;
      margin-bottom: 0.5rem !important;
      filter: drop-shadow(0px 3px 0px #000);
    }

    & h2 :is(b, strong) {
      font-weight: 900;
      background: #67b26f linear-gradient(135deg, #4ca2cd, ease-in-out, #87d28f);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      -webkit-box-decoration-break: clone;
      box-decoration-break: clone;
    }
  }
`;

class ExpenseInfoBox extends Component {
  render() {
    return (
      <div className={styles.expense}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="icon icon-tabler icon-tabler-building-community"
          width="196"
          height="196"
          viewBox="0 0 24 24"
          strokeWidth="1.5"
          fill="none"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
          <path d="M8 9l5 5v7h-5v-4m0 4h-5v-7l5 -5m1 1v-6a1 1 0 0 1 1 -1h10a1 1 0 0 1 1 1v17h-8" />
          <line x1="13" y1="7" x2="13" y2="7.01" />
          <line x1="17" y1="7" x2="17" y2="7.01" />
          <line x1="17" y1="11" x2="17" y2="11.01" />
          <line x1="17" y1="15" x2="17" y2="15.01" />
        </svg>
        <div>
          <h2>
            Ask your manager to get <strong>Polypane</strong>.
          </h2>
          <p>
            <strong>
              Employers are happy to pay for tooling, and usually have end-of-year budget left. Use our{' '}
              <Link to="/black-friday/dear-manager/" style={{ color: '#59a7d1' }}>
                example e-mail
              </Link>{' '}
              to ask your manager!
            </strong>
          </p>
        </div>
      </div>
    );
  }
}

export default ExpenseInfoBox;
